import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Col, Container, Row } from 'react-bootstrap'
import Layout from '../components/layout'
import SEO from '../components/seo'

import Sms from '../components/Index/MainScreen/Desktop/Sms'
import './leaderboard.scss'
import PopupDownload from '../components/PopupDownload/PopupDownload'
import StickyFooterMarquee from '../components/Download/StickyFooterMarquee'
import LeaderboardContent from '../components/LandingPage/LeaderboardContent'
import {
  pokerOneLinksStaticPage,
  rummyOneLinksStaticPage,
} from '../components/one-links'
import {
  generateOrgSchema,
  generateWebsiteSchema,
} from '../lib/generate-schema'

const Leaderboard: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "leaderboard-page/leaderboard-offer.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 1312, layout: FULL_WIDTH)
        }
      }
      bannerImage: file(
        relativePath: { eq: "leaderboard-page/banner-image.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 604, layout: CONSTRAINED)
        }
      }
    }
  `)

  const [isPopupOpen, setIsPopupOpen] = useState(false)

  const handlePlay = () => {
    setIsPopupOpen(true)
  }

  const popupCode = isPopupOpen ? (
    <PopupDownload
      pokerLink={pokerOneLinksStaticPage}
      rummyLink={rummyOneLinksStaticPage}
      close={() => {
        setIsPopupOpen(false)
      }}
      gameName="Leaderboard"
    />
  ) : null

  return (
    <>
      <Layout
        pokerOneLink={pokerOneLinksStaticPage}
        rummyOneLink={rummyOneLinksStaticPage}
      >
        <div className="leader-top-cta">
          <Container>
            <Row>
              <Col className="cta-text">
                <StickyFooterMarquee
                  marqueeText={
                    <>
                      <span>
                         Play Poker & Get ₹30,000 Welcome Bonus. Use Code:
                        <strong> WELCOME30</strong>
                      </span>
                    </>
                  }
                />
              </Col>
              <Col className="cta-button">
                <button type="button" onClick={handlePlay}>
                  Signup Now
                </button>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="leader-full-banner">
          <GatsbyImage
            image={data.banner.childImageSharp.gatsbyImageData}
            loading="eager"
            alt="winners list"
          />
          <div className="full-banner-content">
            <Container>
              <Row>
                <Col xs={6} lg={7} className="banner-image">
                  <GatsbyImage
                    image={data.bannerImage.childImageSharp.gatsbyImageData}
                    loading="eager"
                    alt="winners list"
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div className="leader-page-content leaderboard-content">
          <Container>
            <LeaderboardContent />
            <button type="button" onClick={handlePlay}>
              Play Now
            </button>
          </Container>
        </div>
        {popupCode}
      </Layout>
      <SEO
        title="Play Online Poker &amp; Rummy Tournaments To Earn Cash | Mega"
        description="Download the Mega app to join the biggest online Poker tournaments. Win huge cash prizes on weekly leaderboards. Cash rewards that you can't resist."
        organizationSchema={generateOrgSchema()}
        websiteSchema={generateWebsiteSchema()}
      />
    </>
  )
}

export default Leaderboard
