import React, { useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

const LeaderboardContent: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      allGhostPost(
        filter: {
          tags: { elemMatch: { slug: { eq: "hash-leaderboard_content" } } }
        }
      ) {
        edges {
          node {
            html
          }
        }
      }
    }
  `)

  useEffect(() => {
    let tables = document.querySelectorAll('table')
    for (let i = 0; i < tables.length; i++) {
      let parent = tables[i].parentNode
      let wrapper = document.createElement('div')
      wrapper.className = 'styled-table';
      parent?.insertBefore(wrapper, tables[i])
      wrapper.appendChild(tables[i])
    }
  }, [])

  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: data.allGhostPost.edges[0].node.html,
        }}
      />
    </>
  )
}

export default LeaderboardContent
