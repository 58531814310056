import React from 'react'
import Marquee from 'react-fast-marquee'

interface Props {
  marqueeText?: React.ReactNode
  lang?: string
}

const StickyFooterMarquee: React.FC<Props> = ({ marqueeText, lang }) => {
  const defaultMarquee =
    lang === 'hindi'
      ? '₹3000 वेलकम बोनस प्राप्त करें | कूपन कोड का प्रयोग करें: NEW400'
      : 'Get ₹17000 Welcome Bonus | Use Coupon Code: JONTYRHODES'

  return (
    <Marquee gradientWidth={0} delay={0} speed={50} pauseOnClick pauseOnHover>
      <div>{marqueeText || defaultMarquee}</div>
      <div>{marqueeText || defaultMarquee}</div>
    </Marquee>
  )
}

export default StickyFooterMarquee
